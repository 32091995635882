import React, { useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { BiUser } from 'react-icons/bi'
import { Link } from 'react-router-dom'
import Validation from './Validation'

const Register = () => {

  const [show, setShow] = useState(false);

  const setShowPassword = () => {
      setShow(!show);
  }

    const [values, setValues] = useState({
       name:'',
       email:'', 
       password:'',

    })
  const email = values.email;
  const password = values.password;
    function handelInput (event){
      const newObj = {...values, [event.target.name]:event.target.value}
      setValues(newObj)
    } 

    const [errors, setErrors] = useState({})
    function handleValidation (event){
      event.preventDefault();
     setErrors(Validation(values));
     console.log('Sign up with:', { email, password });
    }

  return (
    <div className='bg-slate-300  h-screen flex justify-center items-center mt-14 md:mt-16 p-8'>

        <div className='border border-slate-400 rounded-md p-4 sm:p-10 shadow-2xl backdrop-filter backdrop-blur-sm relative'>
           <h1 className='text-3xl sm:text-4xl  font-bold text-center mb-8'>Register</h1>

            <form action='' onSubmit={handleValidation}>

                  {/* Email field  */}

                <div className='relative my-8'>
                   <input type="email" name='email'aceholder='' autoComplete='off' className='block w-60 sm:w-72 py-2 px-0 border-0 focus:outline-none bg-transparent border-b-2 border-gray-400 dark:focus:border-blue-500 focus:border-blue-500 peer' onChange={handelInput} />
                   {errors.email && <p className='text-red-500 text-xs'>{errors.email}</p>}

                   <label htmlFor="email" className='absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-90 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6'>Your Email</label>
                   
                   <BiUser className='absolute top-3 right-4 ' />
                </div>

                {/* Password field  */}

                <div className='relative my-8'>
                  <input type={show? 'text':'password'} name='password' placeholder='' autoComplete='off' className='block w-60 sm:w-72 py-2 px-0 border-0 focus:outline-none border-b-2 bg-transparent border-gray-400 dark:focus:border-blue-500 focus:border-blue-500 peer' onChange={handelInput} />

                  {errors.password && <p className='text-red-500 max-w-64 sm:max-w-72 text-xs'>{errors.password}</p>}
                  
                  <label htmlFor="password" className='absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-90 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6'>Enter Password</label>

                  <span onClick={setShowPassword} className="absolute top-3 right-4">{show ? <AiFillEyeInvisible /> : <AiFillEye/>}</span>
                </div>

                {/* Confirm Password field  */}

                <div className='relative my-8'>
                  <input type={show? 'text':'password'} name='password' placeholder='' autoComplete='off' className='block  w-60 sm:w-72 py-2 px-0 border-0 focus:outline-none border-b-2 bg-transparent border-gray-400 dark:focus:border-blue-500 focus:border-blue-500 peer' onChange={handelInput} />
                  
                  {errors.password && <p className='text-red-500 text-xs'>{errors.confirmPassword}</p>}
                  
                  <label htmlFor="password" className='absolute text-sm text-gray-600  duration-300 transform -translate-y-6 scale-90 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-placeholder-shown:translate-y-0 peer-focus:scale-90 peer-focus:-translate-y-6'>Confirm Password</label>

                  <span onClick={setShowPassword} className="absolute top-3 right-4">{show ? <AiFillEyeInvisible /> : <AiFillEye/>}</span>
                </div>
    
                
                <div className='flex justify-between items-center  text-sm sm:text-base'>
                    <div className='flex items-center'>
                       <input type="checkbox" name='' id='' />
                       <label htmlFor="Remember me" className=' mx-2'>Remember me</label>
                    </div>
                </div>
                
                <button type='Submit' className='w-full mb-4 text-[18px] mt-6 p-2 text-gray-300 rounded-lg bg-indigo-700 hover:bg-indigo-800  duration-500'>Register</button>   

                <div>
                    <span className='my-4 text-sm sm:text-base '>Already Have an account? <Link to='/login' className='text-blue-500 text-sm sm:text-base' >Login</Link></span>
                </div> 
            </form>
        </div>
    </div>
  )
}

export default Register