import React from 'react'

const Error = () => {
  return (
    <div className='bg-white md:mt-20 mt-16'>
        <h1>Page not found</h1>
    </div>
  )
}

export default Error